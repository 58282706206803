import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import QuestionPage from "../../components/Quiz/QuestionPage/QuestionPage";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import { get, patch, put, del } from '../../utils/api';
import { useAuth } from '../../contexts/AuthContext';
import { getRandomMusicFile } from "../../utils/musicUtils";
import "./Quiz.css";

const QuizPage = () => {
    const { topicId, lessonId } = useParams();
    const location = useLocation();
    const { user, loggedInChild } = useAuth();

    const [quizSubmission, setQuizSubmission] = useState(null);
    const [quizQuestions, setQuizQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [quizStarted, setQuizStarted] = useState(false);
    const [allQuestionAttempts, setAllQuestionAttempts] = useState([]);
    const [totalQuestions, setTotalQuestions] = useState(0);
    const [ButtonsAreClickable, setButtonsAreClickable] = useState(true);
    const [musicFile, setMusicFile] = useState(null);

    useEffect(() => {
        const fetchQuizSubmission = async () => {
            try {
                console.log('Fetching quiz submission with id:', location.state.quizSubmissionId);
                const response = await get(`/quiz/quiz-submissions/${location.state.quizSubmissionId}`);
                console.log('Full API response:', response);
                
                // The response is the data itself, no need to destructure
                const data = response;
                
                console.log('Extracted data:', data);
                if (!data) {
                    console.error('No data returned from API');
                    // Handle the case where no data is returned
                    return;
                }

                // Check if questionAttempts is empty
                if (data.questionAttempts.length === 0) {
                    // Set QuizStatus to Complete
                    const updatedQuizSubmission = {
                        ...data,
                        QuizStatus: "Complete"
                    };
                    await patch(`/quiz/quiz-submissions/${data._id}`, updatedQuizSubmission);
                    setQuizSubmission(updatedQuizSubmission);

                    // Clear the current topic and delete ChildTopic entry
                    if (loggedInChild) {
                        try {
                            await put(`/children/${loggedInChild._id}/current-topic`, { topicId: null }, true);
                            console.log('Current topic cleared successfully');

                            await del(`/childTopics/${loggedInChild._id}/${topicId}`, true);
                            console.log('ChildTopic entry deleted successfully');
                        } catch (error) {
                            console.error('Error clearing current topic or deleting ChildTopic entry:', error);
                        }
                    }
                } else {
                    setQuizSubmission(data);
                    setAllQuestionAttempts(data.questionAttempts);
                    setQuizQuestions(data.questionAttempts
                        .filter(attempt => attempt.QuestionStatus === "not attempted")
                        .map(attempt => attempt.LessonQuestionId)
                    );
                }
                setTotalQuestions(data.questionAttempts.length);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching quiz submission:', error);
                setLoading(false);
            }
        };
    
        fetchQuizSubmission();
    }, [location.state.quizSubmissionId, loggedInChild, topicId]);

    const isQuizContinuation = quizQuestions.length < allQuestionAttempts.length;

    const handleStartQuiz = () => {
        setQuizStarted(true);
    };

    const handleAnswerSubmit = async (isCorrect, selectedAnswerId, boxSubmission) => {
        setButtonsAreClickable(false);
        const currentQuestionId = quizQuestions[currentQuestionIndex];
        const updatedQuestionAttempts = quizSubmission.questionAttempts.map(attempt => {
            if (attempt.LessonQuestionId === currentQuestionId) {
                return {
                    ...attempt,
                    QuestionStatus: isCorrect ? 'correct' : 'incorrect',
                    chosenAnswer: selectedAnswerId,
                      // Only include BoxTypeAnswers if boxSubmission is provided
                ...(boxSubmission && { BoxTypeAnswers: boxSubmission })
                };
            }
            return attempt;
        });

        if (currentQuestionIndex === quizQuestions.length - 1) {
            const updatedQuizSubmission = {
                ...quizSubmission,
                 QuizStatus: "Complete", 
                questionAttempts: updatedQuestionAttempts
            };

            setQuizSubmission(updatedQuizSubmission);
            

            await patch(`/quiz/quiz-submissions/${quizSubmission._id}`, updatedQuizSubmission);
    
            // Clear the current topic for the logged-in child
            if (loggedInChild) {
                try {
                    await put(`/children/${loggedInChild._id}/current-topic`, { topicId: null }, true);
                    console.log('Current topic cleared successfully');

                    // Delete the ChildTopic entry
                    await del(`/childTopics/${loggedInChild._id}/${topicId}`, true);
                    console.log('ChildTopic entry deleted successfully');
                } catch (error) {
                    console.error('Error clearing current topic or deleting ChildTopic entry:', error);
                }
            }
    
            // Quiz finished
            // You can add navigation to a results page here
        } else {

            const updatedQuizSubmission = {
                ...quizSubmission,
                questionAttempts: updatedQuestionAttempts
            };

            setQuizSubmission(updatedQuizSubmission);

            await patch(`/quiz/quiz-submissions/${quizSubmission._id}`, updatedQuizSubmission);
        }

    };

    useEffect(() => {
        let audio;
        if (quizStarted) {
            getRandomMusicFile().then(file => {
                setMusicFile(file);
                audio = new Audio(file);
                audio.loop = true;
                audio.play();
            });
        }
        return () => {
            if (audio) {
                audio.pause();
                audio.currentTime = 0;
            }
        };
    }, [quizStarted]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="quiz-container">
            {!quizStarted ? (
                <div className="start-container">
                    <h2>
                        {isQuizContinuation 
                            ? 'Are you ready to continue the quiz?' 
                            : 'Are you ready to start the quiz?'}
                    </h2>
                    <button 
                        onClick={handleStartQuiz} 
                        className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
                    >
                        {isQuizContinuation ? 'Continue Quiz' : 'Start Quiz'}
                    </button>
                </div>
            ) : (
                <>
                    {quizSubmission.QuizStatus === 'Complete' ? (
                        <div className="completion-message">
                            {totalQuestions === 0 && (
                                <h2>No questions to complete for now.</h2>
                            )}  
                                <h2>You have finished the lesson! Click on "Let's Learn!" at the top of the page to select a new lesson !</h2>
                            
                        </div>
                    ) : (
                        quizQuestions.length > 0 && (
                            <QuestionPage
                                allQuizQuestionsLength={totalQuestions}
                                outstandingQuestionsLength={quizQuestions.length}
                                setCurrentQuestionIndex={setCurrentQuestionIndex}
                                quizQuestion={quizQuestions[currentQuestionIndex]}
                                onAnswerSelect={handleAnswerSubmit}
                                ButtonsAreClickable={ButtonsAreClickable}
                                setButtonsAreClickable={setButtonsAreClickable}
                                //setQuizIsFinished = {setQuizIsFinished}
                            />
                        )
                    )}
                </>
            )}
        </div>
    );
};

export default QuizPage;
