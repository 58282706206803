import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Login from './components/Login';
import Register from './components/Register';
import AllLessonsFrontend from './components/AllLessonsFrontend';
import HomePageV2 from './components/HomePageV2';
import ChildrenDashboard from './components/ChildrenDashboard';
import QuizPage from './views/Quiz/Quiz';
import ChildTestResults from './components/ChildTestResults';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import Cookies from 'js-cookie';
import {
  NavigationMenu,
  NavigationMenuList,
  NavigationMenuItem,
  NavigationMenuLink,
} from '@/components/shadcn/navigation-menu';
import { Button } from '@/components/shadcn/button';
import { cn } from "@/lib/utils";
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';

function PrivateRoute({ children }) {
  const { isAuthenticated } = useAuth();
  const token = Cookies.get('token');
  return isAuthenticated || token ? children : <Navigate to="/login" />;
}

function NavigationMenuBar() {
  const { logout } = useAuth();
  const location = useLocation();

  const handleLogout = () => {
    logout();
    // You might want to add navigation to the login page here
    // or handle it in your logout function
  };

  const isActive = (path) => {
    if (path === '/') {
      // Consider '/', '/lesson/:lessonId', and '/quiz/:lessonId' as active for "Let's Learn!"
      return location.pathname === '/' || 
             location.pathname.startsWith('/lesson/') || 
             location.pathname.startsWith('/quiz/');
    }
    return location.pathname === path;
  };

  return (
    <div className="w-full bg-slate-200 shadow-md">
      <div className="flex justify-between items-center px-4 py-2">
        <NavigationMenu className="mr-auto">
          <NavigationMenuList>
            <NavigationMenuItem>
              <NavigationMenuLink 
                className={cn(
                  "px-4 py-2 text-sm font-medium rounded-md transition-colors duration-150 ease-in-out",
                  isActive('/children-dashboard') 
                    ? "bg-black text-white" 
                    : "text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                )} 
                href="/children-dashboard"
              >
                Children Dashboard
              </NavigationMenuLink>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <NavigationMenuLink 
                className={cn(
                  "px-4 py-2 text-sm font-medium rounded-md transition-colors duration-150 ease-in-out",
                  isActive('/') 
                    ? "bg-black text-white" 
                    : "text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                )} 
                href="/"
              >
                Let's Learn!
              </NavigationMenuLink>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <NavigationMenuLink 
                className={cn(
                  "px-4 py-2 text-sm font-medium rounded-md transition-colors duration-150 ease-in-out",
                  isActive('/child-test-results') 
                    ? "bg-black text-white" 
                    : "text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                )} 
                href="/child-test-results"
              >
                Child Test Results
              </NavigationMenuLink>
            </NavigationMenuItem>
          </NavigationMenuList>
        </NavigationMenu>
        <Button 
          onClick={handleLogout}
          variant="outline"
          size="sm"
          className="bg-red-500 text-white hover:bg-red-700 transition-colors duration-150 ease-in-out ml-4"
        >
          Logout
        </Button>
      </div>
    </div>
  );
}

function AppContent() {
  const { isAuthenticated } = useAuth();
  const token = Cookies.get('token');

  return (
    <Router>
      <div>
        {(isAuthenticated || token) && <NavigationMenuBar />}

        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route 
            path="/" 
            element={
              <PrivateRoute>
                <AllLessonsFrontend />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/lesson/:lessonId" 
            element={
              <PrivateRoute>
                <HomePageV2 />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/quiz/:topicId/:lessonId" 
            element={
              <PrivateRoute>
                <QuizPage />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/children-dashboard" 
            element={
              <PrivateRoute>
                <ChildrenDashboard />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/child-test-results" 
            element={
              <PrivateRoute>
                <ChildTestResults />
              </PrivateRoute>
            } 
          />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
        </Routes>
      </div>
    </Router>
  );
}

function App() {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
}

export default App;
