import axios from 'axios';
import Cookies from 'js-cookie';
import { get, post, del } from '../utils/api';

const API_URL = process.env.REACT_APP_API_URL;

export const login = async (email, password) => {
  try {
    const response = await post(`${API_URL}/auth/login`, { email, password });
    if (response.token) {
      console.log('Received token:', response.token);
      Cookies.set('token', response.token, { expires: 7 });
      localStorage.setItem('user', JSON.stringify(response.user));
      return { success: true, userData: response.user };
    }
    return { success: false, message: 'Login failed' };
  } catch (error) {
    console.error('Login error:', error);
    return { success: false, message: error.response?.data?.message || 'An error occurred' };
  }
};

export const register = async (email, password) => {
  try {
    const response = await post(`${API_URL}/auth/register`, { email, password });
    if (response.token) {
      console.log('Received token:', response.token);
      Cookies.set('token', response.token, { expires: 7 });
      localStorage.setItem('user', JSON.stringify(response.user));
      return { success: true, userData: response.user };
    }
    return { success: false, message: response.message || 'Registration failed' };
  } catch (error) {
    console.error('Registration error:', error);
    return { success: false, message: error.response?.data?.message || 'An error occurred' };
  }
};

export const logout = () => {
  Cookies.remove('token');
};

export const verifyToken = async (token) => {
  try {
    console.log('Verifying token:', token); // Log the token being sent
    const response = await axios.get(`${API_URL}/auth/verify`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    console.log('Verification response:', response.data); // Log the response
    return response.data;
  } catch (error) {
    console.error('Token verification error:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const forgotPassword = async (email) => {
  try {
    const response = await post(`${API_URL}/auth/forgot-password`, { email });
    return { success: true, message: response.message };
  } catch (error) {
    return { 
      success: false, 
      message: error.response?.data?.message || 'An error occurred' 
    };
  }
};

export const resetPassword = async (token, newPassword) => {
  try {
    const response = await post(`${API_URL}/auth/reset-password`, {
      token,
      newPassword
    });
    return { success: true, message: response.message };
  } catch (error) {
    return { 
      success: false, 
      message: error.response?.data?.message || 'An error occurred' 
    };
  }
};
