import React, { useState, useEffect, useContext } from 'react';
import { get, post, del } from '../utils/api';
import { AuthContext } from '../contexts/AuthContext';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {
  Table,
  TableHeader,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from './shadcn/table';
import { Button } from './shadcn/button';

const ChildrenDashboard = () => {
  const { user, loggedInChild, loginChild, logOutChild } = useContext(AuthContext);
  const [children, setChildren] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [childName, setChildName] = useState('');
  const [childYear, setChildYear] = useState('');
  const [loading, setLoading] = useState(false);
  const [childChanged, setChildChanged] = useState(false);

  useEffect(() => {
    // Log the user and child data from localStorage
    console.log('User in localStorage:', JSON.parse(localStorage.getItem('user')));
    console.log('Logged in child in localStorage:', JSON.parse(localStorage.getItem('loggedInChild')));

    if (user && user._id) {
      fetchChildren();
    }
  }, [user]);

  useEffect(() => {
    if (childChanged && loggedInChild && loggedInChild.name) {
      confirmAlert({
        title: 'Success',
        message: `Logged in as ${loggedInChild.name}`,
        buttons: [
          {
            label: 'OK',
            onClick: () => {},
          },
        ],
      });
      setChildChanged(false);
    }
  }, [loggedInChild, childChanged]);

  const fetchChildren = async () => {
    try {
      const response = await get(`/children?userId=${user._id}`, true);
      setChildren(response);
    } catch (error) {
      console.error('Error fetching children:', error);
    }
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setChildName('');
    setChildYear('');
  };

  const handleAddChild = async (e) => {
    e.preventDefault();
    if (!childName || !childYear) {
      confirmAlert({
        title: 'Error',
        message: 'Please fill in all fields',
        buttons: [
          {
            label: 'OK',
            onClick: () => {},
          },
        ],
      });
      return;
    }

    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure you want to add this child account?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              setLoading(true);
              const response = await post('/children', {
                name: childName,
                yearGroup: childYear,
                currentTopicId: null, // Initialize with null or appropriate value
              }, true);  // Set requireAuth to true
              setChildren([...children, response]);
              closeModal();
              confirmAlert({
                title: 'Success',
                message: 'Child account added',
                buttons: [
                  {
                    label: 'OK',
                    onClick: () => {},
                  },
                ],
              });
            } catch (error) {
              confirmAlert({
                title: 'Error',
                message: 'Failed to add child account',
                buttons: [
                  {
                    label: 'OK',
                    onClick: () => {},
                  },
                ],
              });
            } finally {
              setLoading(false);
            }
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const handleDeleteChild = async (childId) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure you want to delete this child? All progress will be lost',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              setLoading(true);
              await del(`/children/${childId}`, true);
              setChildren(children.filter((child) => child._id !== childId));
              if (loggedInChild && loggedInChild._id === childId) {
                logOutChild();
               // setLoggedInChild(null);
               // localStorage.removeItem('loggedInChild');
              }
              confirmAlert({
                title: 'Deleted!',
                message: 'Child account has been deleted.',
                buttons: [
                  {
                    label: 'OK',
                    onClick: () => {},
                  },
                ],
              });
            } catch (error) {
              confirmAlert({
                title: 'Error',
                message: 'Failed to delete child account',
                buttons: [
                  {
                    label: 'OK',
                    onClick: () => {},
                  },
                ],
              });
            } finally {
              setLoading(false);
            }
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const handleLoginAsChild = (childItem) => {
    if (loggedInChild && loggedInChild._id === childItem._id) {
      // If already logged in as this child, do nothing
      return;
    }

    confirmAlert({
      title: 'Confirm to Login',
      message: `Are you sure you want to log in as ${childItem.name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            loginChild(childItem);
            //setLoggedInChild(childItem);
            //localStorage.setItem('loggedInChild', JSON.stringify(childItem));
            setChildChanged(true);
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <div className="container mx-auto p-4">
      <div className="mb-4 pb-2 border-b border-gray-200">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-semibold text-foreground">All Children</h1>
          <Button onClick={openModal} variant="default">
            Add Child
          </Button>
        </div>
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-2/3">{/*Name*/}</TableHead>
            <TableHead className="w-1/3 text-right">{/*Actions*/}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {children.map((childItem) => (
            <TableRow key={childItem._id}>
              <TableCell className="w-2/3">
                <div className="flex flex-col">
                  <span className="font-medium text-lg">{childItem.name}</span>
                  <span className="text-base text-gray-500">{childItem.yearGroup}</span>
                </div>
              </TableCell>
              <TableCell className="w-1/3 text-right">
                <Button
                  onClick={() => handleLoginAsChild(childItem)}
                  variant={loggedInChild && loggedInChild._id === childItem._id ? "secondary" : "default"}
                  className="mr-2 text-base"
                >
                  {loggedInChild && loggedInChild._id === childItem._id ? 'Logged in' : 'Login as Child'}
                </Button>
                <Button
                  onClick={() => handleDeleteChild(childItem._id)}
                  variant="destructive"
                  className="text-base"
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {modalIsOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <div className="mt-3 text-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Add Child</h3>
              <form className="mt-2 px-7 py-3" onSubmit={handleAddChild}>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="childName">
                    Child Name
                  </label>
                  <input
                    type="text"
                    id="childName"
                    value={childName}
                    onChange={(e) => setChildName(e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="childYear">
                    Child Year Group
                  </label>
                  <select
                    id="childYear"
                    value={childYear}
                    onChange={(e) => setChildYear(e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  >
                    <option value="">Select Year Group</option>
                    <option value="Year 1">Year 1</option>
                    <option value="Year 2">Year 2</option>
                    <option value="Year 3">Year 3</option>
                    <option value="Year 4">Year 4</option>
                    <option value="Year 5">Year 5</option>
                    <option value="Year 6">Year 6</option>
                  </select>
                </div>
                <div className="items-center px-4 py-3">
                  <Button
                    type="submit"
                    disabled={loading}
                    className="w-full"
                  >
                    {loading ? "Submitting..." : "Submit"}
                  </Button>
                </div>
              </form>
              <div className="items-center px-4 py-3">
                <Button
                  onClick={closeModal}
                  variant="secondary"
                  className="w-full"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChildrenDashboard;