// src/components/AllLessonsFrontend.js

import React, { useState, useEffect } from 'react';
import { get, post, put, del } from '../utils/api'; // Import put function
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { confirmAlert } from 'react-confirm-alert';
import { Button } from '@/components/shadcn/button';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from '@/components/shadcn/accordion';
import {
  Table,
  TableHeader,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from '@/components/shadcn/table';

function AllLessonsFrontend() {
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentTopic, setCurrentTopic] = useState(null);
  const [isAllFinished, setIsAllFinished] = useState(false);
  const navigate = useNavigate();
  const { logout, loggedInChild } = useAuth();

  useEffect(() => {
    fetchAllTopics();
    if (loggedInChild) {
      fetchCurrentTopic();
    }
  }, [loggedInChild]);

  const fetchAllTopics = async () => {
    try {
      const response = await get('/topics');
      setTopics(response);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch topics');
      setLoading(false);
    }
  };

  const fetchCurrentTopic = async () => {
    try {
      const response = await get(`/children/${loggedInChild._id}/current-topic`, true);
      setCurrentTopic(response.currentTopicId);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch current topic');
      setLoading(false);
    }
  };

  const handleContinue = async () => {
    try {
      const response = await get(`/childTopics/${loggedInChild._id}/${currentTopic._id}`, true);
      const { currentLesson, learningStage } = response;

      if (learningStage === 'lesson') {
        if (!currentLesson) {
          confirmAlert({
            title: 'Lesson Not Found',
            message: 'The lesson no longer exists, please select another.',
            buttons: [
              {
                label: 'OK',
                onClick: async () => {
                  try {
                    // Delete the ChildTopic entry
                    await del(`/childTopics/${loggedInChild._id}/${currentTopic._id}`, true);
                    
                    // Clear the currentTopicId from the child document
                    await put(`/children/${loggedInChild._id}/current-topic`, { topicId: null }, true);
                    
                    fetchCurrentTopic();
                  } catch (error) {
                    console.error('Error clearing current topic or deleting ChildTopic entry:', error);
                  }
                }
              }
            ]
          });
          return;
        }

        const lessonExists = await get(`/lessons/${currentLesson._id}`);
        if (!lessonExists) {
          confirmAlert({
            title: 'Lesson Not Found',
            message: 'The lesson no longer exists, please select another.',
            buttons: [
              {
                label: 'OK',
                onClick: async () => {
                  try {
                    // Delete the ChildTopic entry
                    await del(`/childTopics/${loggedInChild._id}/${currentTopic._id}`, true);
                    
                    // Clear the currentTopicId from the child document
                    await put(`/children/${loggedInChild._id}/current-topic`, { topicId: null }, true);
                    
                    fetchCurrentTopic();
                  } catch (error) {
                    console.error('Error clearing current topic or deleting ChildTopic entry:', error);
                  }
                }
              }
            ]
          });
          return;
        }
        navigate(`/lesson/${currentLesson._id}`);
      } else if (learningStage === 'quiz') {
        const quizSubmissions = await get(`/quiz/quiz-submissions/search?childId=${loggedInChild._id}&topicId=${currentTopic._id}&lessonId=${currentLesson._id}&QuizStatus=Pending`, true);
        
        if (quizSubmissions && quizSubmissions.length > 0) {
          const quizSubmissionId = quizSubmissions[0]._id;
          navigate(`/quiz/${currentTopic._id}/${currentLesson._id}`, { state: { quizSubmissionId } });
        } else {
          console.error('No pending quiz submission found');
          setIsAllFinished(true);
        }
      } else {
        console.error('Invalid learning stage:', learningStage);
        // Handle invalid learning stage
      }
    } catch (err) {
      confirmAlert({
        title: 'Error',
        message: 'Failed to continue. Please try again.',
        buttons: [
          {
            label: 'OK',
            onClick: () => {},
          },
        ],
      });
    }
  };

  const handleLessonClick = async (topicId, lessonId) => {
    if (!loggedInChild) {
      confirmAlert({
        title: 'Error',
        message: 'Please login as a child before selecting a lesson to learn',
        buttons: [
          {
            label: 'OK',
            onClick: () => {},
          },
        ],
      });
      return;
    }

    try {
      await post('/childTopics/start-learning', { childId: loggedInChild._id, topicId, lessonId }, true);
      await put(`/children/${loggedInChild._id}/current-topic`, { topicId }, true); // Update currentTopicId
      navigate(`/lesson/${lessonId}`);
    } catch (err) {
      confirmAlert({
        title: 'Error',
        message: 'Failed to start learning. Please try again.',
        buttons: [
          {
            label: 'OK',
            onClick: () => {},
          },
        ],
      });
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/login'); // Redirect to login page after logout
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="container mx-auto p-4 min-h-screen flex flex-col">
      {!currentTopic && (
        <div className="mb-4 pb-2 border-b border-gray-200">
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-semibold text-foreground">All Topics</h1>
          </div>
        </div>
      )}

      {currentTopic ? (
        <div className="flex-grow flex items-center justify-center">
          {isAllFinished ? (
            <div className="text-center">
              <h2 className="text-2xl font-bold mb-4">All finished for now!</h2>
              <p>We'll be in touch with next steps!</p>
            </div>
          ) : (
            <Button
              onClick={handleContinue}
              className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Continue from last time
            </Button>
          )}
        </div>
      ) : (
        <Accordion type="single" collapsible className="w-full">
          {topics.map((topic) => (
            <AccordionItem key={topic._id} value={topic._id}>
              <AccordionTrigger className="text-xl font-bold">
                {topic.topic}
              </AccordionTrigger>
              <AccordionContent>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead className="text-lg font-bold">Lesson</TableHead>
                      <TableHead className="text-lg font-bold">Year Group</TableHead>
                      <TableHead className="text-lg font-bold">Difficulty</TableHead>
                      <TableHead className="text-lg font-bold">Action</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {topic.lessons
                      .filter(lesson => lesson.lessonId.lessonVisible)
                      .map((lesson) => (
                        <TableRow key={lesson.lessonId._id}>
                          <TableCell className="text-base font-semibold">{lesson.lessonId.title}</TableCell>
                          <TableCell className="text-base font-semibold">
                            {lesson.lessonId.yearGroupLevel || 'N/A'}
                          </TableCell>
                          <TableCell className="text-base font-semibold">
                            {lesson.lessonId.difficulty || 'N/A'}
                          </TableCell>
                          <TableCell>
                            <Button
                              onClick={() => handleLessonClick(topic._id, lesson.lessonId._id)}
                              className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                              Start Learning
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      )}
    </div>
  );
}

export default AllLessonsFrontend;
