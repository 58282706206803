import React, { useState, useEffect } from "react";
import "./QuestionPage.css";
import baseSway from "../../../media/base-sway-unscreen.gif";
import celebration from "../../../media/celebration-unscreen.gif";

import AddyBaseSway from "../../../media/Addy-Base-Sway.gif";
import AddyDancing from "../../../media/Addy-Dancing.gif";
import AddyBackflip from "../../../media/Addy-Backflip.gif";
import AddyFastBackflip from "../../../media/Addy-Fast-Backflip.gif";
import AddyFastestBackflip from "../../../media/Addy-Fastest-Backflip.gif";
import AddyJumping from "../../../media/Addy-Jumping.gif";
import AddyCelebration from "../../../media/Addy-Celebration.gif";

import yay from "../../../media/streaks/yay.mp3";
import Burning2 from "../../../media/streaks/Burning-2.gif";
import Burning3 from "../../../media/streaks/Burning-3.gif";
import Burning4 from "../../../media/streaks/Burning-4.gif";
import Burning5 from "../../../media/streaks/Burning-5.gif";
import Burning6 from "../../../media/streaks/Burning-6.gif";
import Burning7 from "../../../media/streaks/Burning-7.gif";
import Burning8 from "../../../media/streaks/Burning-8.gif";
import Burning9 from "../../../media/streaks/Burning-9.gif";
import Burning10 from "../../../media/streaks/Burning-10.gif";
import Burning11 from "../../../media/streaks/Burning-11.gif";
import Burning12 from "../../../media/streaks/Burning-12.gif";
import Burning13 from "../../../media/streaks/Burning-13.gif";
import Burning14 from "../../../media/streaks/Burning-14.gif";
import Burning15 from "../../../media/streaks/Burning-15.gif";
import Burning16 from "../../../media/streaks/Burning-16.gif";
import Burning17 from "../../../media/streaks/Burning-17.gif";
import Burning18 from "../../../media/streaks/Burning-18.gif";
import Burning19 from "../../../media/streaks/Burning-19.gif";
import Burning20 from "../../../media/streaks/Burning-20.gif";

import correctSound from "../../../media/correct-answer-sound.mp3";
import ProgressBar from "../../ProgressBar/ProgressBar";

const burningAnimations = {
    2: Burning2,
    3: Burning3,
    4: Burning4,
    5: Burning5,
    6: Burning6,
    7: Burning7,
    8: Burning8,
    9: Burning9,
    10: Burning10,
    11: Burning11,
    12: Burning12,
    13: Burning13,
    14: Burning14,
    15: Burning15,
    16: Burning16,
    17: Burning17,
    18: Burning18,
    19: Burning19,
    20: Burning20
};

function QuestionPage({ allQuizQuestionsLength, outstandingQuestionsLength
    ,setCurrentQuestionIndex, quizQuestion, onAnswerSelect, ButtonsAreClickable, setButtonsAreClickable, setQuizIsFinished }) {
    const [selectedAnswer, setSelectedAnswer] = useState("select");
    const [FreeTextInputValue, setFreeTextInputValue] = useState("");
    const [currentVideoSource, setCurrentVideoSource] = useState(AddyBaseSway);
    const [NextButton, setNextButton] = useState(false);
    const [correctFeedback, setCorrectFeedback] = useState(false);
    const [incorrectFeedback, setIncorrectFeedback] = useState(false);
    const [remainingQuestionsLength, setRemainingQuestionsLength] = useState(outstandingQuestionsLength);
    const [streak, setStreak] = useState(0);
    const [streakAnimation, setStreakAnimation] = useState(null);

    const [blankBoxAnswers, setBlankBoxAnswers] = useState({});
    const [currentBoxIndex, setCurrentBoxIndex] = useState(0);
    const [droppedLabels, setDroppedLabels] = useState({});
    const [shuffledLabels, setShuffledLabels] = useState([]);
    const [nextBoxId, setNextBoxId] = useState(null);

    const correctAnswerSound = new Audio(correctSound);
    const yaySound = new Audio(yay);

    const getStreakAnimation = (streak) => {
        if (streak < 2) return null;
        if (streak > 20) return burningAnimations[20];
        return burningAnimations[streak];
    };

    const handleNextClick = () => {
        if (remainingQuestionsLength <= 1 ) {
            setQuizIsFinished(true);
            
        }
        setCurrentQuestionIndex(currentIndex => currentIndex + 1);
        setNextButton(false);
        setButtonsAreClickable(true);
        setCorrectFeedback(false);
        setIncorrectFeedback(false);
        setRemainingQuestionsLength(remainingQuestionsLength => remainingQuestionsLength - 1);

        // now reset all of the quiz entry states
        setSelectedAnswer("select");
        setFreeTextInputValue("");
        setStreakAnimation(getStreakAnimation(streak));
    };

    useEffect(() => {
        if (quizQuestion.questionType === 'Drag-And-Drop-Question') {
            const labels = quizQuestion.dragBoxes.map(box => box.label);
            setShuffledLabels([...labels].sort(() => Math.random() - 0.5));
            
            // Set initial next box
            const firstBox = quizQuestion.dragBoxes.find(box => box.order === 1);
            if (firstBox) {
                setNextBoxId(firstBox.id);
            }
        }
    }, [quizQuestion]);

    const handleButtonClick = (answer) => {
        setSelectedAnswer(answer.id);
        const isCorrect = answer.id === quizQuestion.correctAnswer;
        
        if (isCorrect) {
            setCorrectFeedback(true);
            correctAnswerSound.play();
            setStreak(prevStreak => {
                const newStreak = prevStreak + 1;
                setStreakAnimation(getStreakAnimation(newStreak));
                
                // Special celebration for every 5th correct answer
                if (newStreak % 5 === 0) {
                    yaySound.play();
                    setCurrentVideoSource(AddyCelebration);
                    setTimeout(() => {
                        // After 10+ streak, revert to FastBackflip
                        if (newStreak >= 10) {
                            setCurrentVideoSource(AddyFastBackflip);
                        }
                        // After 5+ streak but less than 10, revert to Jumping
                        else if (newStreak >= 5) {
                            setCurrentVideoSource(AddyJumping);
                        }
                        // Below 5 streak, revert to BaseSway
                        else {
                            setCurrentVideoSource(AddyBaseSway);
                        }
                    }, 1500);
                } else {
                    // Regular correct answer celebration
                    setCurrentVideoSource(AddyDancing);
                    setTimeout(() => {
                        // After 10+ streak, revert to FastBackflip
                        if (newStreak >= 10) {
                            setCurrentVideoSource(AddyFastBackflip);
                        }
                        // After 5+ streak but less than 10, revert to Jumping
                        else if (newStreak >= 5) {
                            setCurrentVideoSource(AddyJumping);
                        }
                        // Below 5 streak, revert to BaseSway
                        else {
                            setCurrentVideoSource(AddyBaseSway);
                        }
                    }, 1500);
                }
                
                return newStreak;
            });
        } else {
            setIncorrectFeedback(true);
            setStreak(0);
            setStreakAnimation(null);
            setCurrentVideoSource(AddyBaseSway);
        }

        onAnswerSelect(isCorrect, answer.id, null);
        setNextButton(true);
    };

    const handleFreeTextSubmit = (inputValue) => {
        const lowerCaseTexts = quizQuestion.answers.map(answer => answer.text.toLowerCase().trim());
        const isCorrect = lowerCaseTexts.includes(inputValue.toLowerCase().trim());

        if (isCorrect) {
            setCorrectFeedback(true);
            correctAnswerSound.play();
            setStreak(prevStreak => {
                const newStreak = prevStreak + 1;
                setStreakAnimation(getStreakAnimation(newStreak));
                
                // Special celebration for every 5th correct answer
                if (newStreak % 5 === 0) {
                    yaySound.play();
                    setCurrentVideoSource(AddyCelebration);
                    setTimeout(() => {
                        // After 10+ streak, revert to FastBackflip
                        if (newStreak >= 10) {
                            setCurrentVideoSource(AddyFastBackflip);
                        }
                        // After 5+ streak but less than 10, revert to Jumping
                        else if (newStreak >= 5) {
                            setCurrentVideoSource(AddyJumping);
                        }
                        // Below 5 streak, revert to BaseSway
                        else {
                            setCurrentVideoSource(AddyBaseSway);
                        }
                    }, 1500);
                } else {
                    // Regular correct answer celebration
                    setCurrentVideoSource(AddyDancing);
                    setTimeout(() => {
                        // After 10+ streak, revert to FastBackflip
                        if (newStreak >= 10) {
                            setCurrentVideoSource(AddyFastBackflip);
                        }
                        // After 5+ streak but less than 10, revert to Jumping
                        else if (newStreak >= 5) {
                            setCurrentVideoSource(AddyJumping);
                        }
                        // Below 5 streak, revert to BaseSway
                        else {
                            setCurrentVideoSource(AddyBaseSway);
                        }
                    }, 1500);
                }
                
                return newStreak;
            });
        } else {
            setIncorrectFeedback(true);
            setStreak(0);
            setStreakAnimation(null);
            setCurrentVideoSource(AddyBaseSway);
        }

        onAnswerSelect(isCorrect, inputValue, null);
        setNextButton(true);
    
      };

      const handleDropDownSubmit = () => {

        if (!quizQuestion.answers.some(answer => answer.id === selectedAnswer)){
            // Don't submit if "Select Answer" is chosen
            return;
          }

        const isCorrect = (selectedAnswer === quizQuestion.correctAnswer);

        if (isCorrect) {
            setCorrectFeedback(true);
            correctAnswerSound.play();
            setStreak(prevStreak => {
                const newStreak = prevStreak + 1;
                setStreakAnimation(getStreakAnimation(newStreak));
                
                // Special celebration for every 5th correct answer
                if (newStreak % 5 === 0) {
                    yaySound.play();
                    setCurrentVideoSource(AddyCelebration);
                    setTimeout(() => {
                        // After 10+ streak, revert to FastBackflip
                        if (newStreak >= 10) {
                            setCurrentVideoSource(AddyFastBackflip);
                        }
                        // After 5+ streak but less than 10, revert to Jumping
                        else if (newStreak >= 5) {
                            setCurrentVideoSource(AddyJumping);
                        }
                        // Below 5 streak, revert to BaseSway
                        else {
                            setCurrentVideoSource(AddyBaseSway);
                        }
                    }, 1500);
                } else {
                    // Regular correct answer celebration
                    setCurrentVideoSource(AddyDancing);
                    setTimeout(() => {
                        // After 10+ streak, revert to FastBackflip
                        if (newStreak >= 10) {
                            setCurrentVideoSource(AddyFastBackflip);
                        }
                        // After 5+ streak but less than 10, revert to Jumping
                        else if (newStreak >= 5) {
                            setCurrentVideoSource(AddyJumping);
                        }
                        // Below 5 streak, revert to BaseSway
                        else {
                            setCurrentVideoSource(AddyBaseSway);
                        }
                    }, 1500);
                }
                
                return newStreak;
            });
        } else {
            setIncorrectFeedback(true);
            setStreak(0);
            setStreakAnimation(null);
            setCurrentVideoSource(AddyBaseSway);
        }

        onAnswerSelect(isCorrect, selectedAnswer, null);
        setNextButton(true);
 
     };
 
    const ButtonColour = (selectedAnswer, answer) => {
        let buttonClass = 'whitespace-nowrap h-12 px-2 my-2 text-lg text-black border border-2 border-black rounded-lg focus:outline-none';

        if (selectedAnswer === answer.id && answer.id === quizQuestion.correctAnswer) {
            buttonClass += ' correct';
        } else if (selectedAnswer === answer.id && answer.id !== quizQuestion.correctAnswer) {
            buttonClass += ' incorrect';
        } else {
            buttonClass += ' bg-white';
        }

        return buttonClass;
    };


    //-----------------------------------
    // Blank Box Functions
    //-----------------------------------

    const handleBlankBoxInput = (boxId, value) => {
        setBlankBoxAnswers(prev => ({
            ...prev,
            [boxId]: value
        }));
    };

    const handleBlankBoxKeyPress = (e, currentBox) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const nextBox = quizQuestion.blankBoxes.find(box => box.order === currentBox.order + 1);
            if (nextBox) {
                setCurrentBoxIndex(currentBox.order);
                document.getElementById(`box-${nextBox.id}`).focus();
            } else {
                handleBlankBoxSubmit();
            }
        }
    };

    const handleBlankBoxSubmit = () => {
        // Check if all answers are correct, similar to QuestionPage.js
        const allAnswers = quizQuestion.blankBoxes.map(box => ({
            boxId: box.id,
            userAnswer: blankBoxAnswers[box.id] || '',
            correctAnswer: box.answer
        }));

        // Check if each answer is correct
        const isCorrect = quizQuestion.blankBoxes.every(box => 
            blankBoxAnswers[box.id]?.toLowerCase().trim() === box.answer.toLowerCase().trim()
        );

        if (isCorrect) {
            setCorrectFeedback(true);
            correctAnswerSound.play();
            setStreak(prevStreak => {
                const newStreak = prevStreak + 1;
                setStreakAnimation(getStreakAnimation(newStreak));
                
                // Special celebration for every 5th correct answer
                if (newStreak % 5 === 0) {
                    yaySound.play();
                    setCurrentVideoSource(AddyCelebration);
                    setTimeout(() => {
                        // After 10+ streak, revert to FastBackflip
                        if (newStreak >= 10) {
                            setCurrentVideoSource(AddyFastBackflip);
                        }
                        // After 5+ streak but less than 10, revert to Jumping
                        else if (newStreak >= 5) {
                            setCurrentVideoSource(AddyJumping);
                        }
                        // Below 5 streak, revert to BaseSway
                        else {
                            setCurrentVideoSource(AddyBaseSway);
                        }
                    }, 1500);
                } else {
                    // Regular correct answer celebration
                    setCurrentVideoSource(AddyDancing);
                    setTimeout(() => {
                        // After 10+ streak, revert to FastBackflip
                        if (newStreak >= 10) {
                            setCurrentVideoSource(AddyFastBackflip);
                        }
                        // After 5+ streak but less than 10, revert to Jumping
                        else if (newStreak >= 5) {
                            setCurrentVideoSource(AddyJumping);
                        }
                        // Below 5 streak, revert to BaseSway
                        else {
                            setCurrentVideoSource(AddyBaseSway);
                        }
                    }, 1500);
                }
                
                return newStreak;
            });
        } else {
            setIncorrectFeedback(true);
            setStreak(0);
            setStreakAnimation(null);
            setCurrentVideoSource(AddyBaseSway);
        }

        onAnswerSelect(isCorrect, 'N/A', blankBoxAnswers);
        setNextButton(true);
    };

    //-----------------------------------
    // Drag Box Functions
    //-----------------------------------

    const handleLabelClick = (label) => {
        if (!ButtonsAreClickable) return;
        
        // Find the next empty box by order
        const filledBoxIds = Object.keys(droppedLabels);
        const nextBox = quizQuestion.dragBoxes
            .filter(box => !filledBoxIds.includes(box.id))
            .sort((a, b) => a.order - b.order)[0];

        if (nextBox) {
            // Update droppedLabels with the new label
            setDroppedLabels(prev => ({
                ...prev,
                [nextBox.id]: label
            }));

            // Update shuffledLabels
            setShuffledLabels(prev => prev.filter(l => l !== label));

            // Set next box to highlight
            const nextEmptyBox = quizQuestion.dragBoxes
                .filter(box => !filledBoxIds.includes(box.id) && box.id !== nextBox.id)
                .sort((a, b) => a.order - b.order)[0];
            setNextBoxId(nextEmptyBox?.id || null);
        }
    };

    const handleDragBoxSubmit = () => {
        const isCorrect = quizQuestion.dragBoxes.every(box => 
            droppedLabels[box.id] === box.label
        );
        
        if (isCorrect) {
            setCorrectFeedback(true);
            correctAnswerSound.play();
            setStreak(prevStreak => {
                const newStreak = prevStreak + 1;
                setStreakAnimation(getStreakAnimation(newStreak));
                
                // Special celebration for every 5th correct answer
                if (newStreak % 5 === 0) {
                    yaySound.play();
                    setCurrentVideoSource(AddyCelebration);
                    setTimeout(() => {
                        // After 10+ streak, revert to FastBackflip
                        if (newStreak >= 10) {
                            setCurrentVideoSource(AddyFastBackflip);
                        }
                        // After 5+ streak but less than 10, revert to Jumping
                        else if (newStreak >= 5) {
                            setCurrentVideoSource(AddyJumping);
                        }
                        // Below 5 streak, revert to BaseSway
                        else {
                            setCurrentVideoSource(AddyBaseSway);
                        }
                    }, 1500);
                } else {
                    // Regular correct answer celebration
                    setCurrentVideoSource(AddyDancing);
                    setTimeout(() => {
                        // After 10+ streak, revert to FastBackflip
                        if (newStreak >= 10) {
                            setCurrentVideoSource(AddyFastBackflip);
                        }
                        // After 5+ streak but less than 10, revert to Jumping
                        else if (newStreak >= 5) {
                            setCurrentVideoSource(AddyJumping);
                        }
                        // Below 5 streak, revert to BaseSway
                        else {
                            setCurrentVideoSource(AddyBaseSway);
                        }
                    }, 1500);
                }
                
                return newStreak;
            });
        } else {
            setIncorrectFeedback(true);
            setStreak(0);
            setStreakAnimation(null);
            setCurrentVideoSource(AddyBaseSway);
        }


        onAnswerSelect(isCorrect, 'N/A', droppedLabels);
        setNextButton(true);
    };

    // Add handleReset function
    const handleReset = () => {
        // Reset droppedLabels to empty
        setDroppedLabels({});
        // Re-shuffle and restore all labels
        const labels = quizQuestion.dragBoxes.map(box => box.label);
        setShuffledLabels([...labels].sort(() => Math.random() - 0.5));
        // Reset nextBoxId to the first box
        const firstBox = quizQuestion.dragBoxes.find(box => box.order === 1);
        if (firstBox) {
            setNextBoxId(firstBox.id);
        }
    };

    return (
        <div className="question-page">
            <div className="content-wrapper">
            
                <ProgressBar 
                    currentQuestion={allQuizQuestionsLength - remainingQuestionsLength + 1}
                    totalQuestions={allQuizQuestionsLength}
                    labelText="Question"
                />
              
                {streakAnimation && (
                    <img
                        src={streakAnimation}
                        className="streak-animation"
                    />
                )}       

                <img
                    src={currentVideoSource}
                    autoPlay
                    loop={currentVideoSource === AddyBaseSway}
                    muted
                    className="video-style"
                    onEnded={() => setCurrentVideoSource(AddyBaseSway)}
                />
                
                {/* Question text for image-based questions */}
                {quizQuestion.image && quizQuestion.image.trim() !== '' && (
                    <div className="question-text-container">
                        <p>{quizQuestion.question}</p>
                    </div>
                )}
                    
                {/* Question content container */}
                {quizQuestion.questionType !== 'Blank-Box-Question' && 
                 quizQuestion.questionType !== 'Drag-And-Drop-Question' && (
                    <div className="question-image-container">
                        {quizQuestion.image && quizQuestion.image.trim() !== '' ? (
                            <img src={quizQuestion.image} alt="Question" className="question-image" />
                        ) : (
                            <div className="question-text-container">
                                <p>{quizQuestion.question}</p>
                            </div>
                        )}
                    </div>
                )}

                {correctFeedback && (
                    <div className="feedback correct-feedback">
                        <p>Correct!</p>
                        {quizQuestion.correctFeedback && <p>{quizQuestion.correctFeedback}</p>}
                    </div>
                )}    

                {incorrectFeedback && (
                    <div className="feedback incorrect-feedback">
                        <p>Incorrect!</p>
                        {quizQuestion.incorrectFeedback && <p>{quizQuestion.incorrectFeedback}</p>}
                    </div>
                )}
                
            

            {quizQuestion.questionType === 'Multiple-Choice-Question' && (
                <div className="answers-container">
                    {quizQuestion.answers.map((answer) => (
                        <button
                            key={answer.id}
                            onClick={ButtonsAreClickable ? () => handleButtonClick(answer) : undefined}
                            className={ButtonColour(selectedAnswer, answer)}
                            style={{ width: '48%' }}
                        >
                            {answer.text}
                        </button>
                    ))}
                </div>
            )}

            {quizQuestion.questionType === 'Drop-Down-List' && (
                <main className="drop-down-container">
                    <p className="form__label"></p>
                    <select
                        value={selectedAnswer}
                        onChange={(e) => { setSelectedAnswer(e.target.value) }}
                        className="form__border w-full my-2"
                    >
                        <option value="select">Select Answer</option>
                        {quizQuestion.answers.map((answer) => (
                            <option key={answer.id} value={answer.id}>
                                {answer.text}
                            </option>
                        ))}
                    </select>
                    <div className="mt-4">
                        {!NextButton && (
                            <button
                                onClick={ButtonsAreClickable ? () => handleDropDownSubmit() : undefined}
                                className="h-12 px-4 text-lg text-black bg-red-500 hover:bg-red-700 rounded-lg focus:outline-none"
                            >
                                Submit
                            </button>
                        )}
                    </div>
                </main>
            )}

            {quizQuestion.questionType === 'Free-Text-Question' && (
                <main className="free-text-container">
                    <input
                        type="text"
                        value={FreeTextInputValue}
                        onChange={(e) => { setFreeTextInputValue(e.target.value) }}
                        placeholder="Enter answer here"
                        className="w-full h-12 p-3 text-lg border-2 border-gray-600 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                    <div className="mt-4">
                        {!NextButton && (
                            <button
                                onClick={ButtonsAreClickable ? () => handleFreeTextSubmit(FreeTextInputValue) : undefined}
                                className="h-12 px-4 text-lg text-black bg-red-500 hover:bg-red-700 rounded-lg focus:outline-none"
                            >
                                Submit
                            </button>
                        )}
                    </div>
                </main>
            )}

            {quizQuestion.questionType === 'Blank-Box-Question' && (
                <>
                    <div className="relative w-full h-[45vh] flex justify-center items-center">
                        <div className="relative h-full w-full flex justify-center items-center">
                            <div className="relative h-full" style={{ maxWidth: '100%' }}>
                                <img 
                                    src={quizQuestion.image} 
                                    alt="Question" 
                                    className="h-full w-auto object-contain max-w-full"
                                    draggable="false"
                                />
                                {quizQuestion.blankBoxes.map((box) => (
                                    <div
                                        key={box.id}
                                        className="absolute"
                                        style={{
                                            left: `${box.x}%`,
                                            top: `${box.y}%`,
                                            width: `${box.width}%`,
                                            height: `${box.height}%`
                                        }}
                                    >
                                        <input
                                            id={`box-${box.id}`}
                                            type="text"
                                            value={blankBoxAnswers[box.id] || ''}
                                            onChange={(e) => handleBlankBoxInput(box.id, e.target.value)}
                                            onKeyPress={(e) => handleBlankBoxKeyPress(e, box)}
                                            className={`w-full h-full p-1 border-2 ${
                                                !ButtonsAreClickable
                                                    ? blankBoxAnswers[box.id]?.toLowerCase().trim() === box.answer.toLowerCase().trim()
                                                        ? 'border-green-500 bg-green-100'
                                                        : 'border-red-500 bg-red-100'
                                                    : 'border-black bg-white'
                                            }`}
                                            disabled={!ButtonsAreClickable}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {!NextButton && (
                        <div className="mt-4">
                            <button
                                onClick={handleBlankBoxSubmit}
                                className="h-12 px-4 text-lg text-white bg-blue-500 hover:bg-blue-700 rounded-lg focus:outline-none"
                            >
                                Submit
                            </button>
                        </div>
                    )}
                </>
            )}

            {quizQuestion.questionType === 'Drag-And-Drop-Question' && (
                <>
                    <div className="relative w-full h-[45vh] flex justify-center items-center">
                        <div className="relative h-full w-full flex justify-center items-center">
                            <div className="relative h-full" style={{ maxWidth: '100%' }}>
                                <img 
                                    src={quizQuestion.image} 
                                    alt="Question" 
                                    className="h-full w-auto object-contain"
                                    draggable="false"
                                />
                                {quizQuestion.dragBoxes.map((box) => (
                                    <div
                                        key={box.id}
                                        className={`absolute border-2 ${
                                            !ButtonsAreClickable
                                                ? droppedLabels[box.id] === box.label
                                                    ? 'border-green-500 bg-green-100'
                                                    : 'border-red-500 bg-red-100'
                                                : box.id === nextBoxId
                                                ? 'border-green-500 bg-green-100'
                                                : 'border-gray-400 bg-gray-200'
                                        }`}
                                        style={{
                                            left: `${box.x}%`,
                                            top: `${box.y}%`,
                                            width: `${box.width}%`,
                                            height: `${box.height}%`
                                        }}
                                    >
                                        {droppedLabels[box.id] && (
                                            <div className="w-full h-full flex items-center justify-center">
                                                <div className="p-2 bg-blue-500 text-white rounded">
                                                    {droppedLabels[box.id]}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="flex mt-4">
                        <div className="flex flex-wrap justify-center gap-2">
                            {shuffledLabels.map((label, index) => (
                                <div
                                    key={index}
                                    className="p-2 bg-blue-500 text-white rounded cursor-pointer hover:bg-blue-600"
                                    onClick={() => handleLabelClick(label)}
                                >
                                    {label}
                                </div>
                            ))}
                        </div>
                        <div className="flex mx-2">
                            {ButtonsAreClickable && Object.keys(droppedLabels).length > 0 && (
                                <button
                                    onClick={handleReset}
                                    className="h-12 px-4 text-lg text-black bg-yellow-500 hover:bg-yellow-600 rounded-lg focus:outline-none"
                                >
                                    Reset Labels
                                </button>
                            )}
                            {!NextButton && shuffledLabels.length === 0 && (
                                <button
                                    onClick={handleDragBoxSubmit}
                                    className="h-12 px-4 text-lg text-white bg-blue-500 hover:bg-blue-600 rounded-lg focus:outline-none"
                                >
                                    Submit
                                </button>
                            )}
                        </div>
                    </div>
                </>
            )}

            {NextButton && (
                <div className="mt-4">
                    <button
                        onClick={() => handleNextClick()}
                        className="h-12 px-4 text-lg text-white bg-blue-500 hover:bg-blue-700 rounded-lg focus:outline-none"
                    >
                        {remainingQuestionsLength <= 1 ? 'Finish Quiz' : 'Next Question'}
                    </button>
                </div>
            )}
        </div>
        </div>
    );
}

export default QuestionPage;
