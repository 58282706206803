import React from 'react';
import "./ProgressBar.css";

const ProgressBar = ({ currentQuestion, totalQuestions, labelText }) => {
    const progressPercentage = ((currentQuestion / totalQuestions) * 100).toFixed(2);

    return (
        <div className="progress-bar-container">
            <div className="progress-bar" style={{ width: `${progressPercentage}%` }}></div>
            <div className="progress-text">
                {labelText} {currentQuestion} / {totalQuestions}
            </div>
        </div>
    );
};

export default ProgressBar;