import React, { createContext, useState, useContext, useEffect } from 'react';
import Cookies from 'js-cookie';
import { verifyToken } from '../services/authService';


const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [loggedInChild, setLoggedInChild] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [authError, setAuthError] = useState(null);
  // Remove userId state

  useEffect(() => {
    const verifyAuthentication = async () => {
      const token = Cookies.get('token');
      const storedUser = JSON.parse(localStorage.getItem('user'));
      if (token && storedUser) {
        try {
          const userData = await verifyToken(token);
          setUser(userData);
          setIsAuthenticated(true);
          setAuthError(null);
          
          // Update localStorage with fresh data
          localStorage.setItem('user', JSON.stringify(userData));
          
          // Retrieve logged in child from localStorage
          const storedChild = localStorage.getItem('loggedInChild');
          if (storedChild) {
            setLoggedInChild(JSON.parse(storedChild));
          }
        } catch (error) {
          // ... error handling ...
          localStorage.removeItem('user');
        }
      } else {
        setAuthError(null);
      }
      setIsLoading(false);
    };

    verifyAuthentication();
  }, []);

  const loginChild = (childData) => {
    setLoggedInChild(childData);
    localStorage.setItem('loggedInChild', JSON.stringify(childData));
  }

  const logOutChild = () => {
    setLoggedInChild(null);
    localStorage.removeItem('loggedInChild');
  };

  const login = (userData) => {
    const userToStore = {
      ...userData,
      _id: userData._id.toString()
    };
    setUser(userToStore);
    setIsAuthenticated(true);
    setAuthError(null);
    localStorage.setItem('user', JSON.stringify(userToStore));
  };

  const logout = () => {
    Cookies.remove('token');
    localStorage.removeItem('user');
    logOutChild();
    setIsAuthenticated(false);
    setUser(null);
    setAuthError(null);
  };

  const setChildLogin = (childData) => {
    setLoggedInChild(childData);
    localStorage.setItem('loggedInChild', JSON.stringify(childData));
  };

  const childLogout = () => {
    setLoggedInChild(null);
    localStorage.removeItem('loggedInChild');
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ 
      isAuthenticated, 
      user,
      loginChild,
      logOutChild,
      login, 
      logout, 
      authError, 
      setAuthError,
      loggedInChild,
      setChildLogin,
      childLogout
    }}>
      {children} 
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}

export { AuthContext };